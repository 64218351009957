import React from 'react'
import styled from 'styled-components'
import { defaultColor } from '~src/common/cssTips'

const MenuIconWrapper = styled.div`
  width: 28px;
  height: 16px;
  margin-right: 10px;
  top: 0;
  position: relative;
  .line {
    position: absolute;
    top: calc(50% - 1.5px);
    left: calc(50% - 14px);
    width: 28px;
    height: 3px;
    border-radius: 5px;
    background: ${defaultColor};
    opacity: 1;
    transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out, opacity 0.2s ease-in-out, color 0.2s ease-in-out;
  }
  & .line-1 {
    margin: -8px 0 0 0;
    transform-origin: center center;
  }
  & .line-3 {
    margin: 8px 0 0 0;
    transform-origin: center center;
  }

  &.opened {
    .line {
      background: ${defaultColor};
    }
    .line-1 {
      margin: 0;
      transform: rotateZ(45deg);
    }
    .line-2 {
      opacity: 0;
    }
    .line-3 {
      margin: 0;
      transform: rotateZ(-45deg);
    }
  }
`

export type MenuIconProps = { isOpen?: boolean } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export const MenuIcon = React.memo(React.forwardRef<HTMLDivElement, MenuIconProps>(({ isOpen, ...props }, ref) => (
  <MenuIconWrapper
    {...props as any}
    className={(isOpen ? 'opened' : '') + (props.className ? props.className : '')}
    ref={ref}
  >
    <div className="line line-1" />
    <div className="line line-2" />
    <div className="line line-3" />
  </MenuIconWrapper>
)))
