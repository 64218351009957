export const isZhCN = () => {
  if (typeof window !== 'undefined') {
    return window.isZhCN()
  }

  return false
}

export const getZhCNtranscript = (
  localeIsZhCN: string,
  localeNonZhCN: string,
) => (isZhCN() ? localeIsZhCN : localeNonZhCN)
