import axios from 'axios'
import { windowSearchParams } from './urlSearchParams'
import { getShareId } from './fromShare'
import { getDeviceId } from './deviceId'

export const sendKolLog = () => {
  // tslint:disable-next-line: strict-type-predicates
  if (typeof window !== 'undefined') {
    const shareId = getShareId()
    if (shareId) {
      const params = windowSearchParams()

      const axiosInstance = axios.create({
        baseURL: `https://${params.base || 'api.elisiapp.com'}/api/report/`,
        timeout: 30000,
      })
      axiosInstance.post('landing', {
        device_id: getDeviceId(),
        from_share_id: shareId,
        landing_at: (new Date().getTime() / 1000) | 0,
      })
    }
  }
}
