import { nanoid } from 'nanoid'

export const DEVICE_ID = 'd_id'

let deviceId = ''

export const getDeviceId = () => {
  // tslint:disable-next-line: strict-type-predicates
  if (typeof window === 'undefined' || deviceId) {
    return deviceId
  }
  deviceId = window.localStorage.getItem(DEVICE_ID) || deviceId
  if (!deviceId) {
    deviceId = nanoid()
    window.localStorage.setItem(DEVICE_ID, deviceId)
  }

  return deviceId
}
