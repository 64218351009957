export const getSearchParams = (search: string): Record<string, string> => {
  const paramsArr = search.replace('?', '').split('&')
  const params: Record<string, string> = {}
  if (paramsArr.length) {
    paramsArr.forEach(paramPair => {
      const paramArr = paramPair.split('=')
      if (paramArr.length === 2) {
        params[paramArr[0]] = decodeURIComponent(paramArr[1])
      }
    })
  }

  return params
}

export const boxUrlParams = (params: Record<string, string | number>) =>
  params
    ? Object.keys(params)
      .map(key => `${key}=${encodeURIComponent(params[key])}`)
      .join('&')
    : ''

export const appendUrl = (url: string, params: Record<string, string | number>) => {
  const paramsStr = boxUrlParams(params)
  if (paramsStr) {
    url = `${url}?${boxUrlParams(params)}`
  }

  return url
}

let cachedWindowSearchParams: Record<string, string> | null = null

export const windowSearchParams = () => {
  // tslint:disable-next-line: strict-type-predicates
  if (typeof window === 'undefined') {
    return {}
  } else if (cachedWindowSearchParams) {
    return cachedWindowSearchParams
  } else {
    cachedWindowSearchParams = getSearchParams(window.location.search)

    return cachedWindowSearchParams
  }
}
