import React from 'react'
import { createGlobalStyle } from 'styled-components'
import normalize from 'polished/lib/mixins/normalize'
import { Header } from '~src/components/Header'
import { SiteData } from '~src/graphql'
import { APKUrlProvider } from '~src/context/apk-url'

const GlobalStyle = createGlobalStyle`
  ${normalize() as any}
  html {
    font-family: PingFangSC, PingFang SC, 'Open Sans', -apple-system, BlinkMacSystemFont, SourceHanSansCN-Regular, SourceHanSansCN, sans-serif;
  }
`

export interface LayoutProps {
  data: SiteData,
  location: {
    pathname: string;
  }
}

export default class Layout extends React.PureComponent<LayoutProps> {
  render () {
    const { children, location } = this.props

    return (
      <APKUrlProvider>
        <GlobalStyle />
        <Header
          path={location.pathname}
        />
        {children}
      </APKUrlProvider>
    )
  }
}
