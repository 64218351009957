export const localStorageSet = (key: string, data: any, expire: number = 5) => {
  // tslint:disable-next-line: strict-type-predicates
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(
      key,
      JSON.stringify({
        data,
        expire: new Date().getTime() + expire * 24 * 60 * 60 * 1000,
      }),
    )
  }
}

export const localStorageGet = (key: string) => {
  // tslint:disable-next-line: strict-type-predicates
  if (typeof window === 'undefined') {
    return null
  }

  const storage = window.localStorage.getItem(key)

  if (storage) {
    const obj = JSON.parse(storage)
    if (new Date().getTime() < obj.expire) {
      return obj.data
    } else {
      window.localStorage.removeItem(key)

      return null
    }
  }

  return null
}
