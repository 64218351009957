import React, { useEffect } from 'react'
import downloadLinks from '~src/data/download-links.json'
import { isZhCN } from '~src/utils/locale'

const APKUrlContext = React.createContext({ androidAPK: downloadLinks.androidAPK, androidAPK_CN: downloadLinks.androidAPK_CN })

const zhCNUrlOrigin = 'https://api.elisiapp.cn';
const enUrlOrigin = 'https://api.elisiapp.com';

const androidApkPath =  '/api/config/app_update?os=android&version_no=1&lang=en&application_id=com.ariesapp.elisi.play_store'

const androidApkCnPath = '/api/config/app_update?os=android&version_no=1&lang=en&application_id=com.ariesapp.elisi.cn'

export const useAPKUrl = () => React.useContext(APKUrlContext)

const useFetchAPkUrl = (primaryUrl: string, secondaryUrl: string) => {
  const [apkUrl, setApkUrl] = React.useState<string>()

  useEffect(() => {
    async function fetchAPKUrl() {
      try {
        const response = await fetch(primaryUrl)
        const data = await response.json()
        setApkUrl(data.result.url)
      } catch (e) {
        try {
          const response = await fetch(secondaryUrl)
          const data = await response.json()
          setApkUrl(data.result.url)
        } catch (e) {
        }
      }
    }
    fetchAPKUrl()
  }, [])

  return apkUrl
}

export const APKUrlProvider = ({ children }: { children: React.ReactNode }) => {
  const zhCN = isZhCN()
  const primaryUrlOrigin = zhCN ? zhCNUrlOrigin : enUrlOrigin;
  const secondaryUrlOrigin = zhCN ? enUrlOrigin : zhCNUrlOrigin;

  const androidAPK = useFetchAPkUrl(primaryUrlOrigin + androidApkPath, secondaryUrlOrigin + androidApkPath) ?? downloadLinks.androidAPK
  const androidAPK_CN = useFetchAPkUrl(primaryUrlOrigin + androidApkCnPath, secondaryUrlOrigin + androidApkCnPath) ?? downloadLinks.androidAPK_CN

  return (
    <APKUrlContext.Provider value={{ androidAPK, androidAPK_CN }}>
      {children}
    </APKUrlContext.Provider>
  )
}
