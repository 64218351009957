import { css } from 'styled-components'

export const defaultColor = '#1a1a1a'
export const descriptionColor = '#666666'
export const contrastDefaultColor = '#fff'
export const primaryColor = '#24cf5f'
export const secondaryColor = '#14aa46'
export const paragraphColor = '#8c9897'
export const strongColor = '#333333'

export const lgBreakPoint = '1200px'
export const mdBreakPoint = '960px'
export const smBreakPoint = '768px'
export const xsBreakPoint = '480px'

export const defaultFont = css`
  color: ${defaultColor};
`
export const primaryFont = css`
  color: ${primaryColor};
`

export const sectionInlineSize = css`
  flex: 1;
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
`

export const resetButton = css`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-decoration: none;
`
