import styled, { css } from 'styled-components'
import {
  defaultColor,
  smBreakPoint,
  contrastDefaultColor,
  primaryColor } from '~src/common/cssTips'

export const Button = styled.button<{ accent?: 'default' | 'primary' | 'secondary', noMargin?: boolean }>`
  background: ${props => props.accent === 'primary' ? primaryColor : props.accent === 'secondary' ? 'transparent' : defaultColor};
  color: ${props => props.accent === 'secondary' ? primaryColor : contrastDefaultColor};
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  border-radius: 28px;
  padding: 12px 34px;
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  border: solid 1px ${props => props.accent === 'primary' ? primaryColor : props.accent === 'secondary' ? primaryColor : defaultColor};
  outline: none;

  &:hover {
    opacity: 0.8;
  }
  &:not(:last-child) {
    margin-right: 25px;
  }

  &[data-large="true"] {
    font-size: 18px;
    padding: 16px 34px;
  }

  ${props => props.noMargin
    ? css`
      margin-right: 0 !important;
    `
    : ''
  }

  @media screen and (max-width: ${smBreakPoint}) {
    &[data-large="true"] {
      font-size: 14px;
      padding: 12px 34px;
    }
  }
`
