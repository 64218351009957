/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/global.css'
import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/500.css'
import '@fontsource/open-sans/600.css'
import '@fontsource/open-sans/700.css'
import '@fontsource/open-sans/800.css'

export const onClientEntry = () => {
    const isBrowser = typeof window !== 'undefined'

    function getOS() {
        const userAgent = window.navigator.userAgent
        const platform = window.navigator.platform
        const macPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
        const windowPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
        const iosPlatforms = ['iPhone', 'iPad', 'iPod']

        if (macPlatforms.indexOf(platform) !== -1) {
            return 'MacOS'
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            return 'iOS'
        } else if (windowPlatforms.indexOf(platform) !== -1) {
            return 'Windows'
        } else if (/Android/.test(userAgent)) {
            return 'Android'
        } else if (/Linux/.test(platform)) {
            return 'Linux'
        } else {
            return 'Unknown'
        }
    }

    function isZhCN() {
        return navigator.language === 'zh-CN' || (typeof URLSearchParams !== 'undefined' && new URLSearchParams(window.location.search).get('lang') === 'zh-CN')
    }

    if (isBrowser) {
        window.document.documentElement.className += ' elisi-os-' + getOS()
        window.document.documentElement.className += ' elisi-lang-' + (isZhCN() ? 'zh-cn' : 'en-us')
    }

    // @ts-expect-error:: inject global variables
    window.getOS = getOS
    // @ts-expect-error:: inject global variables
    window.isZhCN = isZhCN
}