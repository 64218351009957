import axios from 'axios'
import { DEVICE_ID, getDeviceId } from './deviceId'
import { localStorageGet, localStorageSet } from './localStorageExpire'
import { appendUrl, windowSearchParams } from './urlSearchParams'

export const FROM_SHARE_ID = 'from_share_id'
// export const SHARE_SOURCE_ID = 'source'

const params = windowSearchParams()

export const getShareId = () => {
  let shareId = localStorageGet(FROM_SHARE_ID)
  if (!shareId && params[FROM_SHARE_ID]) {
    shareId = params[FROM_SHARE_ID]
    localStorageSet(FROM_SHARE_ID, shareId)
  }

  return shareId
}

export const getAppUrl = () => {
  const shareId = getShareId()
  const APP_URL = `https://${params.app || 'next.elisiapp.com'}/`
  if (shareId) {
    return appendUrl(APP_URL, {
      [FROM_SHARE_ID]: shareId,
      [DEVICE_ID]: getDeviceId(),
    })
  } else {
    return APP_URL
  }
}

interface ShareInfo {
  userId: string
  type: string
  sourceForMixpanel: 'shareElisi' | 'shareHabit'
}
export const getShareInfo = async (): Promise<ShareInfo | undefined> => {
  const id = getShareId()

  if (id) {
    const res = await axios
      .get<{
        code: number
        msg: string
        result: { user_id: string; share_type: string }
      }>(`https://${params.base || 'api.elisiapp.com'}/api/share/author`, {
        params: {
          from_share_id: getShareId(),
        },
      })
      .catch((err) => {})
    if (res) {
      const userId = res.data?.result?.user_id
      const type = res.data?.result?.share_type

      let sourceForMixpanel: 'shareElisi' | 'shareHabit' = 'shareElisi'
      switch (type) {
        case 'invite_user':
          sourceForMixpanel = 'shareElisi'
          break
        case 'share_habit':
          sourceForMixpanel = 'shareHabit'
          break
        default:
          break
      }

      return {
        userId,
        type,
        sourceForMixpanel,
      }
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}
