import React, { useState, useCallback, useEffect, useMemo } from 'react'
import styled, { css } from 'styled-components'
import Link from 'gatsby-link'
import BrandIcon from '../assets/svg/brand.svg'
import { MenuIcon } from '../components/MenuIcon'
import { defaultFont, primaryFont } from '../common/cssTips'
import {
  defaultColor,
  sectionInlineSize,
  lgBreakPoint,
  mdBreakPoint,
  smBreakPoint,
  xsBreakPoint,
} from '~src/common/cssTips'
import { Button } from '~src/units/Button'
import {
  FROM_SHARE_ID,
  getAppUrl,
  getShareId,
  getShareInfo,
} from '~src/utils/fromShare'
// import sendGaForTryNow from '~src/utils/sendGaForTryNow'
import ClipboardJS from 'clipboard'
import { sendKolLog } from '~src/utils/sendKolLog'
import { isZhCN, getZhCNtranscript } from '~src/utils/locale'
import { mixpanel } from '~src/marketing/mixpanel'

export const headerHeight = 80
export const headerMsgHeight = 54
const navItemPaddingForMdBreakPoint = 16
const navItemPaddingForLgBreakPoint = 20
const navItemPaddingForNoBreakPoint = 32

const navItemBlockStyle = css`
  display: inline-block;
  height: ${headerHeight}px;
  line-height: ${headerHeight}px;
  text-transform: uppercase;
  padding: 0 ${navItemPaddingForNoBreakPoint}px 0 0;
  box-sizing: border-box;
  transition: color 0.5s ease;
  font-weight: 600;

  &:first-child {
    padding-left: ${navItemPaddingForNoBreakPoint}px;
  }

  @media screen and (max-width: ${lgBreakPoint}) {
    padding: 0 ${navItemPaddingForLgBreakPoint}px 0 0;
    &:first-child {
      padding-left: ${navItemPaddingForLgBreakPoint}px;
    }
  }
  @media screen and (max-width: ${mdBreakPoint}) {
    padding: 0 ${navItemPaddingForMdBreakPoint}px 0 0;
    &:first-child {
      padding-left: ${navItemPaddingForMdBreakPoint}px;
    }
  }
  @media screen and (max-width: ${smBreakPoint}) {
    padding: 0 ${navItemPaddingForMdBreakPoint}px;
    height: ${headerHeight * 0.75}px;
    line-height: ${headerHeight * 0.75}px;
  }
`

const Wrapper = styled.div`
  position: fixed;
  height: ${headerHeight}px;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid transparent;
  @media screen and (max-width: ${smBreakPoint}) {
    height: ${headerHeight * 0.75}px;
  }

  &.scrolled {
    border-color: #e9e9e9;
  }
`

const Content = styled.div`
  ${sectionInlineSize};
  padding: 0 80px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  align-items: center;
  @media screen and (max-width: ${lgBreakPoint}) {
    padding: 0 40px;
  }
  @media screen and (max-width: ${mdBreakPoint}) {
    padding: 0 10px;
  }
`

const NavBar = styled.nav`
  @media screen and (max-width: ${smBreakPoint}) {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
    &.opened {
      max-height: calc(100vh - ${headerHeight * 0.75}px);
      overflow: auto;
    }
    z-index: 2;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background-color: #fff;
  }
`

const BaseLinkCSS = css`
  display: inline-block;
  height: 100%;
  text-decoration: none;
  font-size: 14px;

  @media screen and (max-width: ${smBreakPoint}) {
    width: 100%;
    padding: 0;
    text-align: center;
    border-bottom: 1px solid #ebebeb;
  }
`

const BaseLink = styled.a`
  ${BaseLinkCSS}
`

const BrandLink = styled(Link)`
  text-decoration: none;
  svg {
    height: ${headerHeight / 2}px;
    @media screen and (max-width: ${mdBreakPoint}) {
      height: ${(headerHeight / 2) * 0.6}px;
      #brand-svg-text {
        display: none;
      }
    }
  }
`

const NavAppCSS = css`
  ${BaseLinkCSS};
  ${navItemBlockStyle};
  ${defaultFont};
  cursor: pointer;
  &:hover {
    ${primaryFont};
  }
`
const NavApp = styled(BaseLink)`
  ${NavAppCSS};
`

const NavLink = styled(Link)`
  ${NavAppCSS};
  ${({ path, to }: { path: string; to: string }) =>
    ((path === '/' && to === '/home') || path.startsWith(to)) && primaryFont}
`

const NavSignIn = styled(NavApp)`
  &:last-child {
    padding-right: 0;
  }
  @media screen and (max-width: ${smBreakPoint}) {
    padding-right: 16px !important;
    ${Button} {
      background: unset;
      border: unset;
      color: ${defaultColor};
      font-weight: bold;
      padding: 0;
      text-transform: uppercase;
    }
  }
`

const NavMenuWrapper = styled.div`
  display: none;
  margin: 0 16px;
  @media screen and (max-width: ${smBreakPoint}) {
    display: block;
  }
`

const HeaderMsg = styled.div`
  ${sectionInlineSize};
  font-size: 16px;
  height: ${headerMsgHeight}px;
  background-color: #24cf5f;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -${headerMsgHeight}px;
  padding: 0 15px;
  width: 100%;
  @media screen and (max-width: ${xsBreakPoint}) {
    font-size: 12px;
    bottom: -${headerMsgHeight * 0.75}px;
    height: ${headerMsgHeight * 0.75}px;
  }
`

export interface HeaderProps {
  path: string
}

export interface HeaderState {
  menuClosed: boolean
}

export const Header = React.memo(({ path }: HeaderProps) => {
  const [menuClosed, setMenuClosed] = useState(true)
  const [showMsg, setShowMsg] = useState(false)

  const isZhLang = useMemo(() => isZhCN(), [])

  useEffect(() => {
    const shareId = getShareId()
    if (shareId) {
      new ClipboardJS('.app-download-link', {
        text: () => `${FROM_SHARE_ID}=${shareId}`,
      })
      // 暂时不显示share msg
      setShowMsg(false)
      sendKolLog()
    }
  }, [])

  useEffect(() => {
    setMenuClosed(true)
  }, [path])

  const handleMenuClick = useCallback(() => {
    setMenuClosed((closed) => !closed)
  }, [])

  const handleSignInClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      getShareInfo().then((r) => {
        if (r) {
          mixpanel.track('H5Page_clickBegin', {
            source: r.sourceForMixpanel,
            account: r.userId,
          })
        }
      })
      window.open(getAppUrl(), '_blank')
    },
    []
  )

  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    function detectScroll(e: Event) {
      setScrolled(window.document.documentElement.scrollTop > 0 ? true : false)
    }

    window && window.document.addEventListener('scroll', detectScroll, false)

    return () => {
      window &&
        window.document.removeEventListener('scroll', detectScroll, false)
    }
  }, [])

  const handleClickBlogNav = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()

      if (isZhLang) {
        window.open(
          'https://www.yuque.com/books/share/48320383-1472-4e35-8fff-892b66e02332',
          '_blank',
          'noreferrer noopener'
        )
      } else {
        window.location.href = '/blog'
      }
    },
    [isZhLang]
  )

  return (
    <Wrapper className={scrolled ? 'scrolled' : ''}>
      <Content>
        <BrandLink to="/">
          <BrandIcon />
        </BrandLink>
        <NavBar className={menuClosed ? '' : 'opened'}>
          <NavLink path={path} to="/home">
            {getZhCNtranscript('主页', 'Home')}
          </NavLink>
          <NavApp onClick={handleClickBlogNav}>
            {getZhCNtranscript('博客', 'Blog')}
          </NavApp>
          <NavLink path={path} to={isZhLang ? '/about-zh' : '/about'}>
            {getZhCNtranscript('关于', 'About')}
          </NavLink>
          {/* <NavLink path={path} to="/digital-bullet-journal-app">
            Bullet Journal
          </NavLink> */}
          <NavLink path={path} to={isZhLang ? '/privacy-zh' : '/privacy'}>
            {getZhCNtranscript('隐私政策', 'Privacy Policy')}
          </NavLink>
          {/* <NavApp onClick={sendGaForTryNow} target="_blank" className="app-download-link">
            {getZhCNtranscript('现在试用', 'Try Now')}
          </NavApp>
          */}
          <NavSignIn onClick={handleSignInClick} target="_blank">
            <Button>{getZhCNtranscript('开始使用', 'Start Now')}</Button>
          </NavSignIn>
        </NavBar>
        <NavMenuWrapper>
          <MenuIcon isOpen={!menuClosed} onClick={handleMenuClick} />
        </NavMenuWrapper>
      </Content>
      {showMsg && (
        <HeaderMsg>
          Registered users who share elisi with others get a limited membership
          for free; click below to register for a trial~
        </HeaderMsg>
      )}
    </Wrapper>
  )
})
